<template>
  <div class="help-box">
    <!-- <Search></Search> -->
    <!-- 帮助默认 -->
    <div class="content">
      <h2 class="zhonga">《快洋淘浏览器用户隐私协议》</h2>
      <div class="textnoe">
        <div>
          为保护您的个人信息安全，快洋淘浏览器（下称“快洋淘”）服务的提供者江苏星芒网络科技有限公司(下称“快洋淘”或“我们”）制定了快洋淘隐私政策（下称“本政策”）。
        </div>
        <div>
          我们深知个人信息对您的重要性，我们将按法律法规要求，采取相应安全保护措施，尽力保护您的个人信息安全可控。在使用快洋淘产品或服务前，请您务必仔细阅读并充分理解本政策，在确认充分理解并同意后使用相关产品或服务。一旦您开始使用快洋淘产品或服务，即表示您已充分理解并同意本政策。如您对本政策内容有任何疑问、意见或建议，可以直接联系快洋淘在线客服。
        </div>
      </div>
      <div class="textTle">
        <div class="tlei">本政策将帮助您了解以下内容：</div>
        <div>1． 我们如何收集信息</div>
        <div>2． 我们如何使用Cookie和同类技术</div>
        <div>3． 我们如何存储和保护信息</div>
        <div>4． 我们如何使用信息</div>
        <div>5． 我们如何对外提供信息</div>
        <div>6． 未成年人信息保护</div>
        <div>7． 通知和修订</div>
        <div>8． 如何联系我们及救济</div>
      </div>
      <div class="textTle">
        <div class="tlei">1. 我们如何收集信息</div>
        <div class="tlei2">1.1. 完成快洋淘账户注册</div>
        <div>
          为便于我们向您提供快洋淘服务，您需要提供您的手机号码及短信验证码以注册并创建快洋淘账号，否则您将不能使用快洋淘服务；
        </div>
        <div>
          完成快洋淘账号注册后，您可登录快洋淘平台，点击“个人信息”对您的昵称、手机号登录密码等个人信息进行修改。
        </div>
        <div class="tlei2">1.2. 向您提供快洋淘产品或服务</div>
        <div>
          当您在使用快洋淘服务过程中，为向您提供更契合您需求的快洋淘服务，维护快洋淘服务的正常运行，改进及优化您对快洋淘服务的体验并保障您的账号安全，我们会收集关于您使用的服务以及使用方式的信息并将这些信息进行关联，这些信息包括
        </div>
        <div>
          a.
          使用信息：我们会收集您作为快洋淘用户使用快洋淘服务时提交或产生的查询请求等信息，以及您所在团队使用快洋淘服务时提交或产生的信息。
        </div>
        <div>
          b.
          日志信息：当您使用快洋淘平台提供的服务时，我们会自动收集您对我们服务的详细使用情况，作为有关网络日志保存。例如您的搜索查询内容、常用功能使用频率、终端设备地址、IP地址、登录状态、访问日期和时间及您的访问记录等。请注意，单独的使用信息、日志信息等是无法识别特定自然人身份的信息。如果我们将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将有可能被视为个人信息，除取得您授权或法律法规另有规定外，我们会将该类个人信息做匿名化、去标识化处理。
        </div>
        <div class="tlei2">1.3. 您向我们提供的信息</div>
        <div>
          在快洋淘服务使用过程中，您可以评论或留言等方式对快洋淘产品及服务的体验问题反馈，帮助我们更好地了解您使用我们产品或服务的体验和需求，改善我们产品或服务,为此我们会记录您的联系信息、反馈的问题或建议，以便我们进一步联系您或及时向您反馈我们的处理意见。
        </div>
        <div class="tlei2">
          1.4. 在您使用个性化服务过程中收集的信息
          为向您提供更好、更个性化的服务，满足您的运营管理需求，您可以选择快洋淘平台所提供的多样服务，在此过程中，我们可能会收集您使用该个性服务的相关信息。
        </div>
        <div>
          a.
          在您使用个性化服务过程中收集的信息为向您提供更好、更个性化的服务，满足您的运营管理需求，您可以选择快洋淘平台所提供的多样服务，在此过程中，我们可能会收集您使用该个性服务的相关信息。
        </div>
        <div>b. 第三方向我们提供的信息</div>
        <div>
          我们会从关联方、第三方合作伙伴或通过其他合法途径获取您的信息。
        </div>
        <div class="tlei2">1.5. 其他目的收集信息</div>
        <div>
          我们将信息用于本政策未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的时，会事先征求您的同意（确认同意的方式：如勾选、弹窗、站内信、邮件、短信等方式）。
          您理解并同意，我们向您提供的功能和服务场景是不断迭代升级的，如我们未在上述场景中明示您需要收集的个人信息，我们将会通过页面提示、交互设计等方式另行向您明示信息收集的内容、范围和目的并征得您同意。
        </div>
        <div class="tlei2">1.5. 征得同意的例外</div>
        <div>
          根据相关法律法规规定，以下情形中收集您的个人信息无需征得您的授权同意：
        </div>
        <div>a. 与国家安全、国防安全有关的；</div>
        <div>b. 与公共安全、公共卫生、重大公共利益有关的；</div>
        <div>c. 与犯罪侦查、起诉、审判和判决执行等有关的；</div>
        <div>
          d.
          出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；
        </div>
        <div>e. 所收集的个人信息是您自行向社会公众公开的；</div>
        <div>
          f.
          从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；
        </div>
        <div>g. 根据您的要求签订合同所必需的；</div>
        <div>
          h.
          用于维护所提供的产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；
        </div>
        <div>i. 为合法的新闻报道所必需的；</div>
        <div>
          j.
          学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；
        </div>
        <div>k. 法律法规规定的其他情形。</div>
      </div>
      <div class="textTle">
        <div class="tlei">2. 我们如何使用 Cookie等技术</div>
        <div class="tlei2">
          2.1.
          为确保网站正常运转、为您提供更个性化的用户体验和服务，我们会在您的计算机或移动设备上使用Cookie等本地存储技术存储不涉及隐私和安全的相关必要信息。
        </div>
        <div class="tlei2">
          2.2. 我们不会将
          Cookie用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除
          Cookie。有关详情，请参见
          AboutCookies.org。您可根据自己的偏好管理或删除Cookie。您可以在计算机浏览器的设置上选择拒绝接受Cookie或限制某些Cookie或清除Cookie。如您进行前述操作，您仍可以使用快洋淘服务，但可能导致某些功能的使用受限。
        </div>
        <div class="tlei">3. 我们如何存储和保护信息</div>
        <div class="tlei2">
          3.1.
          我们在中华人民共和国境内运营中收集和产生的信息，将存储在中国境内。以下情形除外：
        </div>

        <div>a. 法律法规有明确规定；</div>
        <div>b. 获得您的授权同意；</div>
        <div>
          c.
          您使用快洋淘跨境交易服务，且需要向境外传输您的个人信息完成交易的。针对以上情形，我们会确保依据本政策及国家法律法规要求对您的个人信息提供足够的保护。我们将向您提供便捷的管理个人信息的机制，您可联系我们行使依法享有的权利。
          如我们终止服务或运营，我们将及时停止继续收集您个人信息的活动，同时会遵守相关法律法规要求提前向您通知。
        </div>
        <div class="tlei2">
          3.2.
          我们会采取各种预防措施来保护您的个人信息，以保障您的个人信息免遭丢失、盗用和误用，以及被擅自取阅、披露、更改或销毁。包括但不限于采取以下措施：
        </div>
        <div>
          d.
          我们会采用符合业界标准、合理可行的安全防护措施保护您提供的个人信息，防止您的个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失；
        </div>
        <div>e. 我们会采用数据脱敏技术增强个人信息在使用中的安全性；</div>
        <div>f. 我们会采用受信赖的保护机制防止数据遭到恶意攻击；</div>
        <div>
          g. 其他安全措施。
          互联网环境并非百分之百安全，尽管我们有这些安全措施，但请注意在互联网上不存在“完善的安全措施”，我们将尽力确保您的信息的安全性。
        </div>
      </div>
      <div class="textTle">
        <div class="tlei">
          4.
          我们如何使用您的信息收集您的个人信息是为了向您提供服务及提升服务质量，为了实现这一目的，我们会将您的信息用于下列用途：
        </div>
        <div>
          a. 向您提供快洋淘产品或服务，并维护、改进、优化这些服务及服务体验；
        </div>
        <div>
          b.
          为预防、发现、调查欺诈、侵权、危害安全、非法或违反与我们或与我们关联公司的协议、政策或规则的行为，保护您、其他用户或公众以及我们或我们关联公司的合法权益，我们会使用或整合您的个人信息、服务使用信息、设备信息、日志信息以及我们关联公司、合作伙伴取得您授权或依据法律共享的信息，来综合判断您账户及交易风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施；
        </div>
        <div>c. 经您同意的其他用途。</div>
      </div>
      <div class="textTle">
        <div class="tlei">5. 我们如何对外提供信息</div>
        <div class="tlei2">5.1. 共享</div>
        <div>
          我们承诺对您的信息严格保密，非因法律法规或监管政策要求的外，我们仅基于如下情形依法与第三方共享您的信息：
        </div>
        <div>
          a.
          快洋淘部分功能或服务将由第三方或我们与第三方共同提供，因此只有共享您的信息，才能实现快洋淘服务功能或提供您需要的服务；
        </div>
        <div>
          b.
          为了便于您使用第三方服务，您可以选择使用快洋淘账号登陆第三方网站或APP或进行校验，我们将基于您的同意将信息共享给第三方。需要注意的是，第三方网站可能单独的隐私政策，共享信息受到该等隐私政策的约束，请您咨询阅读并作出是否共享的判断；
        </div>
        <div>
          c.
          当我们接到检查或投诉时，我们可能会将与投诉相关的您的信息共享给消费者权益保护部门或监管机构，法律法规明确禁止提供的除外；
        </div>
        <div>
          d.
          在获取您同意的情况下的共享：获得您授权同意后，我们会与其他方共享您的个人信息。
        </div>
        <div class="tlei2">5.2. 转让</div>
        <div>
          我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：
        </div>
        <div>
          a.
          在获取授权同意的情况下转让：获得您的授权同意后，我们会向其他方转让您的个人信息；
        </div>
        <div>
          b.
          在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。
        </div>
        <div class="tlei2">5.3. 对外提供信息时事先征得授权同意的例外</div>
        <div class="tlei2">
          以下情形中，共享、转让、披露您的个人信息无需事先征得您的授权同意：
        </div>
        <div>a. 与国家安全、国防安全有关的；</div>
        <div>b. 与公共安全、公共卫生、重大公共利益有关的；</div>
        <div>
          c.
          与犯罪侦查、起诉、审判和判决执行等有关的。在符合法律法规的前提下，当我们收到上述披露信息的请求时，我们会要求接收方必须出具与之相应的法律文件，如传票或调查函；
        </div>
        <div>
          d.
          出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
        </div>
        <div>e. 您自行向社会公众公开的个人信息；</div>
        <div>
          f.
          从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。
          根据法律规定，共享、转让经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、转让及披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。
        </div>
      </div>
      <div class="textTle">
        <div class="tlei">6. 未成年人信息的保护</div>
        <div>
          6.1.
          我们建议：任何未成年人参加网上活动应事先取得家长或其法定监护人（以下简称"监护人"）的同意。我们将根据国家相关法律法规的规定保护未成年人的相关信息。
        </div>
        <div>
          6.2.
          对于经父母或监护人同意使用我们的产品或服务而收集未成年人个人信息的情况，我们只会在法律法规允许，父母或监护人明确同意或者保护未成年人所必要的情况下使用，共享或转让此信息。我们将根据国家相关法律法规及本政策的规定保护未成年人的个人信息。
        </div>
      </div>
      <div class="textTle">
        <div class="tlei">7. 通知和修订</div>
        <div class="tlei2">
          我们可能适时修改本政策的条款，该等修改构成本政策的一部分。我们会通过我们网站、移动端或其他官方路径发出更新版本或以其他适当方式公开发布对本政策所做的任何变更。如您仍然继续使用快洋淘服务的，即表示同意受经修订的本政策的约束。
        </div>
      </div>
      <div class="textTle">
        <div class="tlei">8. 如何联系我们及救济</div>
        <div>
          8.1.
          如您对本政策或您个人信息的相关事宜有任何疑问、意见或建议，您可以通过联系快洋淘客服等方式与我们联系。我们将在收到您相关联系信息后尽快回复您。
        </div>
        <div>
          8.2.
          如果您对我们的回复不满意，特别是您认为我们的个人信息处理行为损害了您的合法权益，您还可以通过向杭州仲裁委员会提起仲裁以寻求救济。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "login_ckop",
  data() {
    return {};
  },
  head() {
    return {
      title: "隐私协议-快洋淘浏览器",
    };
  },
  methods: {},
};
</script>

<style scoped lang="less">
.content {
  width: 1200px;
  // height: 975px;
  background: #fff;
  margin: auto;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 40px;
  padding-bottom: 120px;
}

.help-box {
  background-color: #fff;
  // height: 4386px;
  padding-top: 40px;
}

.zhonga {
  width: 312px;
  height: 33px;
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #2c354b;
  line-height: 33px;
  margin: auto;
}

.textnoe {
  width: 1120px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #2c354b;
  line-height: 29px;
  margin-top: 31px;
}

.textTle {
  width: 1120px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #2c354b;
  line-height: 28px;

  div {
    margin-top: 11px;
  }

  .tlei {
    width: 1120px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #2c354b;
    line-height: 24px;
    margin-bottom: 12px;
    margin-top: 28px;
  }

  .tlei2 {
    width: 1120px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #2c354b;
    line-height: 24px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
}
</style>
